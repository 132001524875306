import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// importing all the themes

import StakingOne from "../themes/staking-one";


class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={ <StakingOne /> } />
            
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default MyRouts;