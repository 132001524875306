import React from 'react';
import { ConnectWallet } from '@thirdweb-dev/react';

const Header = () => {
    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar gameon-navbar navbar-expand">
                <div className="container header">
                    {/* Logo */}
                    <a className="navbar-brand" href="/">
                        <img src="https://assets-global.website-files.com/65187d41155bcd98bacc2c57/6518835afb1dc81e95bb21e9_kalichain_logo.svg" alt="kalichain Logo" />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar Nav */}
                    <ul className="navbar-nav items mx-auto">
                        <li className="nav-item">
                            <a href="/staking-one" className="nav-link">Home</a>
                        </li>
                        
                        
                    </ul>
                    {/* Navbar Icons */}
                    <ul className="navbar-nav icons">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#search">
                                <i className="icon-magnifier" />
                            </a>
                        </li>
                    </ul>
                    {/* Navbar Toggler */}
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="icon-menu m-0" />
                            </a>
                        </li>
                    </ul>
                    {/* Navbar Action Button */}
                    <ul className="navbar-nav action">
                    <li className="nav-item ml-2">
                        {/* Utilisation de ConnectWallet au lieu d'un lien */}
                        <ConnectWallet className="btn ml-lg-auto btn-bordered-white"><i className="icon-wallet mr-md-2" /></ConnectWallet>
                    </li>
                </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;