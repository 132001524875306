import React from 'react';
import { 
  ThirdwebProvider, 
  metamaskWallet, 
  coinbaseWallet, 
  walletConnect, 
  trustWallet 
} from '@thirdweb-dev/react';
import { Kalichain } from "@thirdweb-dev/chains";
import MyRouts from './routers/routes';

function App() {
  return (
    <ThirdwebProvider
      activeChain={Kalichain}
      clientId="557bffa5f2b967c6bcc86ff4e3bbc243"
      supportedWallets={[
        metamaskWallet(), 
        coinbaseWallet(), 
        walletConnect(), 
        trustWallet()
      ]}
    >
      <div>
        <MyRouts />
      </div>
    </ThirdwebProvider>
  );
}

export default App;
